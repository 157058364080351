body {
  font-family: "Courier New", Courier, monospace;
  background-color: black;
}

@media only screen and (min-width: 1500px) and (min-height: 700px) {
  #root {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100%;
  position: fixed;
}
